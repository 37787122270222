import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Footer extends Component {
  render() {
    return (      
      <footer>
      <div className="container pt-3 hideMegaMenu">
        <div className="text-center">
          <img
            className="pb-5"
            style={{
              width: "290px"
            }}
            src="/uploads/ob-logo.png"
            alt="o.b. professioneel forum"
          />
        </div>
        <div className="row">
          <div className="col-md-4">
            <a className="main" href="/">
              Homepage
            </a>
            <a href="/service/sitemap">Sitemap</a>
            <a href="/service/contact">Contact</a>
            <a href="/service/juridische-kennisgeving">
              Juridische kennisgeving
            </a>
            <a href="/service/privacybeleid">Privacy statement</a>
            <a href="/service/cookies">Cookie statement</a>
          </div>
          <div className="col-md-4">
            <a
              className="main"
              href="/lesmaterialen/lespakket-gratis-bestellen"
            >
              Lespakket gratis bestellen
            </a>
            <a href="/lesmaterialen/lespakket-gratis-bestellen">
              Bestel het nu
            </a>                  
          </div>
          <div className="col-md-4">
            <a className="main" href="/multimedia">
              Multimedia voor seksuele voorlichting
            </a>
            <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">
              Educatieve film "Opgroeien"
            </a>
            <a href="/multimedia/online-kennistests/quiz-puberteit">
              Quiz puberteit
            </a>
            <a href="/multimedia/online-kennistests/quiz-menstruatie">
              Quiz menstruatie
            </a>
            <a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene">
              Quiz menstruatiehygiëne
            </a>
            <a href="/multimedia/oefenbladen">Oefenbladen</a>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            <a className="main" href="/lesmaterialen">
              Lesmaterialen voor seksuele voorlichtingslessen
            </a>
            <a href="/lesmaterialen/themagebieden">Themagebieden</a>
            <a href="/lesmaterialen/speciale-pakketten">
              Speciale pakketten
            </a>
          </div>
          <div className="col-md-4">
            <a className="main" href="/lessen">
              Lesgeven
            </a>
            <a href="/lessen/richtlijnen">
              Richtlijnen voor seksuele voorlichting
            </a>
            <a href="/lessen/lesvoorbereiding">Lesvoorbereiding</a>
            <a href="/lessen/tips">Tips voor seksuele voorlichting</a>
          </div>
          <div className="col-md-4">
            <a className="main" href="/service">
              Service en veelgestelde vragen
            </a>
            <a href="/service/veel-gestelde-vragen">
              Veelgestelde vragen
            </a>
            <a href="/service/belangrijke-links">Belangrijke links</a>
            <a href="/service/contact">Contact &amp; feedback</a>
          </div>
        </div>
        <div className="row mt-5 pb-3">
          <div className="col-12 font-smaller">
            <p>© Johnson &amp; Johnson Consumer B.V. 2006 - 2019.</p>
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-instellingen</button>
            <p style={{"float": "left"}}>
              Deze website is gepubliceerd door Johnson &amp; Johnson                               
              Consumer B.V., die als enige verantwoordelijk is voor de
              inhoud. De site is bedoeld voor een Nederlands publiek.
              <br />
              Voor het laatst bijgewerkt: 14 oktober 2019.
            </p>
          </div>
        </div>
      </div>
    </footer>                
        
    );
  }
}
export default Footer;