import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
        <div>
      <div dangerouslySetInnerHTML={{ __html: `

<nav class="navbar navbar-light light-blue lighten-4 d-sm-none">

    <a class="navbar-brand" href="/">
    <img src="/uploads/voorlichtingsles-logo.png" height="40" alt="Voorlichtingsles Volwassen Worden">
  </a>
    
  
  <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
      <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
    </button>

  
  <div class="collapse navbar-collapse" id="navbarSupportedContent1">

    
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-effect waves-light" href="/">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lessen">Lessen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen">Lesmaterialen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/multimedia">Multimedia aanpak</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen/lespakket-gratis-bestellen">Lespakket gratis bestellen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/service">Service</a>
      </li>
    </ul>
    

  </div>
  

</nav>

    <header class="bg-blue d-none d-sm-block pt-3">

        <div class="container-fluid" style="border-bottom:rgb(238,38,110) 7px solid">
            <div class="container">
                <div class="row hideMegaMenu">
                    <div class="col-12">
                        <img src="/uploads/voorlichtingsles-logo.png" alt="Voorlichtingsles Volwassen Worden">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a class="show-menu" href="/" data-value="-1"><i class="fas fa-home fa-2x"></i></a>
                        <a class="show-menu" href="/lessen" data-value="0">Lessen</a>
                        <a class="show-menu" href="/lesmaterialen" data-value="1">Lesmaterialen</a>
                        <a class="show-menu" href="/multimedia" data-value="2">Multimedia aanpak</a>
                        <a class="show-menu" href="/lesmaterialen/lespakket-gratis-bestellen" data-value="3">Lespakket gratis bestellen</a>
                        <a class="show-menu" href="/service" data-value="4">Service</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="megamenu container-fluid" data-show="0" style="z-index:99999;position:absolute;display:none">
            <div id="megamenu_0" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_richtlijnen.jpg" class="img-fluid" alt="Richtlijnen">
                                <a href="/lessen/richtlijnen" class="d-block mt-1 tab-menu stretched-link">Richtlijnen voor de seksuele voorlichting</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_lesvoorbereiding.jpg" class="img-fluid" alt="Lesvoorbereiding">
                                <a href="/lessen/lesvoorbereiding" class="d-block mt-1 tab-menu stretched-link">Lesvoorbereiding</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_tips.jpg" class="img-fluid" alt="Tips">
                                <a href="/lessen/tips" class="d-block mt-1 tab-menu stretched-link">Tips voor de seksuele voorlichtingslessen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_1" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/themagebieden" style="color:inherit">Themagebieden</a>
                                    <div>
                                        <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="d-block mt-1 tab-menu">Ontwikkeling en puberteit</a>
                                        <a href="/lesmaterialen/themagebieden/geslachtsorganen" class="d-block mt-1 tab-menu">Geslachtsorganen</a>
                                        <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="d-block mt-1 tab-menu">Menstruatiecyclus</a>
                                        <a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="d-block mt-1 tab-menu">Menstruatie hygi&#xEB;ne</a>
                                        <a href="/lesmaterialen/themagebieden/tampongebruik" class="d-block mt-1 tab-menu">Tampongebruik</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Speciale pakketten</a>
                                    <div>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="d-block mt-1 tab-menu">Lesmateriaal voor meisjes</a>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="d-block mt-1 tab-menu">Lesmateriaal voor jongens</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">

                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Lespakket gratis bestellen</a>
                                    <div>
                                        <a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="d-block mt-1 tab-menu">Docentenhandleiding</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_2" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_instructiefilm.jpg" class="img-fluid" alt="Instructiefilm">
                                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden" class="d-block mt-1 tab-menu stretched-link">Instructiefilm over seksuele voorlichting &#xAB;Volwassen worden&#xBB;</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_kennistest.jpg" class="img-fluid" alt="Online kennistest">
                                <a href="/multimedia/online-kennistests" class="d-block mt-1 tab-menu stretched-link">Online kennistest</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_oefeningen.jpg" class="img-fluid" alt="Oefenbladen">
                                <a href="/multimedia/oefenbladen" class="d-block mt-1 tab-menu stretched-link">Oefenbladen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_3" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_4_primaironderwijs.jpg" class="img-fluid" alt="Bestel nu gratis">
                                <a href="/lesmaterialen/lespakket-gratis-bestellen" class="d-block mt-1 tab-menu stretched-link">Bestel nu gratis</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_4" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_vragen.jpg" class="img-fluid" alt="Belangrijke vragen">
                                <a href="/service/veel-gestelde-vragen" class="d-block mt-1 tab-menu stretched-link">Belangrijke vragen over menstruatie en menstruatiehygi&#xEB;ne</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_links.jpg" class="img-fluid" alt="Service">
                                <a href="/service/belangrijke-links" class="d-block mt-1 tab-menu stretched-link">Belangrijke links</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_contact.jpg" class="img-fluid" alt="Contact">
                                <a href="/service/contact" class="d-block mt-1 tab-menu stretched-link">Contact</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
        </div>
    </header>
    
    <main class="hideMegaMenu">
        <div class="container-fluid opening mb-3">            
            <div class="container bg-blauw">
                <div class="row">
                    <div class="col-md-6 px-4 py-3">
                        <h1 class="h1-responsive text-white">Cyclus en menstruatie</h1>

                    </div>
                    <div class="col-md-6">
                        <img src="/uploads/img_4456_2_lesmateriaal.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid hideMegaMenu">
            <div class="container">

	<div id="breadcrumb"><div class="bc my-3 d-none d-sm-block ">
	<a href="/">Home</a> &gt; <a href="/lesmaterialen">Lesmaterialen voor seksuele voorlichtingslessen</a> &gt; <a href="/lesmaterialen/themagebieden">Themagebieden</a> &gt; <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie">Cyclus en menstruatie</a>
</div>
</div>

                <div class="row">
                    <div class="col-md-8">
                        <div id="content">
		<h1 class="h1-responsive my-3  ">Cyclus en menstruatie</h1>
         <p class="lead ">De eerste periode is een bijzondere ervaring voor jonge meisjes. 
Kennis van de vrouwelijke cyclus is essentieel als het gaat om het begrijpen van de verbanden tussen seksualiteit en vruchtbaarheid.
</p>
        <p></p><p>Het lesmateriaal biedt verschillende voor de leeftijd geschikte opties om de hormonale achtergrond en het verloop van de cyclus, de menstruatie of de ontwikkeling van de zwangerschap te verklaren.</p>


<div class="card">
    <div class="card-body">
        <h5 class="card-title">
            Hier alle documenten bij het themagebied met een klik te downloaden.</h5>
        <a href="/uploads/verzamelingen/lesmateriaal=themagebied-cyclus.zip" class="btn bg-blauw text-white waves-effect waves-light">Download</a>
    </div>
</div>

<div class="B00562">
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/achtergrondinformatie-de-vrouwelijke-cyclus-meer-als-alleen-die-dagen-4597.png" class="img-fluid" alt="Achtergrondinformatie:" de vrouwelijke cyclus – meer als alleen "die dagen">
    </div>
    <div class="col-md-6">
        <h5>Achtergrondinformatie: De vrouwelijke cyclus &#x2013; meer als alleen &quot;die dagen&quot;</h5>
        <p>Informatie voor onderwijzers: historische en wetenschappelijke feiten over de vrouwelijke cyclus en de menstruatie.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/achtergrondinformatie-de-vrouwelijke-cyclus-meer-als-alleen-die-dagen-4597.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/startklaar-voor-de-les-de-vrouwelijke-cyclus-4598.png" class="img-fluid" alt="Startklaar" voor de les: vrouwelijke cyclus>
    </div>
    <div class="col-md-6">
        <h5>Startklaar voor de les: de vrouwelijke cyclus</h5>
        <p>Interessante feiten en vragen om aan de slag te gaan met dit onderwerp. Spannende en educatieve informatie kan de sfeer van een gesprek losser maken en de dialoog stimuleren.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/startklaar-voor-de-les-de-vrouwelijke-cyclus-4598.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/achtergrondinformatie-menstruatie-en-sport-4599.png" class="img-fluid" alt="Achtergrondinformatie:" menstruatie en sport>
    </div>
    <div class="col-md-6">
        <h5>Achtergrondinformatie: menstruatie en sport</h5>
        <p>Informatie voor onderwijzers: historische en wetenschappelijke feiten voor de uitoefening van sport tijdens de menstruatie. Omgaan met niet-deelname aan lichamelijke opvoeding.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/achtergrondinformatie-menstruatie-en-sport-4599.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/startklaar-voor-de-les-de-menstruatie-4601.png" class="img-fluid" alt="Startklaar" voor de les: menstruatie>
    </div>
    <div class="col-md-6">
        <h5>Startklaar voor de les: de menstruatie</h5>
        <p>Interessante feiten en vragen om aan de slag te gaan met dit onderwerp. Spannende en educatieve informatie kan de sfeer van een gesprek losser maken en de dialoog stimuleren.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/startklaar-voor-de-les-de-menstruatie-4601.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/tips-advies-over-hoe-je-op-een-natuurlijke-manier-met-de-menstruatie-omgaat-4602.png" class="img-fluid" alt="Tips:" advies over hoe je op een natuurlijke manier met de menstruatie omgaat>
    </div>
    <div class="col-md-6">
        <h5>Tips: advies over hoe je op een natuurlijke manier met de menstruatie omgaat</h5>
        <p>Advies en tips die helpen om normale klachten op natuurlijke wijze te voorkomen of te verlichten.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/tips-advies-over-hoe-je-op-een-natuurlijke-manier-met-de-menstruatie-omgaat-4602.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/van-eisprong-tot-bevruchting-4603.png" class="img-fluid" alt="Van" eisprong tot bevruchting>
    </div>
    <div class="col-md-6">
        <h5>Van eisprong tot bevruchting</h5>
        <p>Van eisprong (ovulatie) tot bevruchting tot implantatie, fascinerende microscoopbeelden bieden inzicht in de vruchtbaarheid van het vrouwelijk lichaam. Met verklarend lesmateriaal voor de seksuele voorlichtingslessen.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/van-eisprong-tot-bevruchting-4603.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/van-eisprong-tot-bevruchting-4604.png" class="img-fluid" alt="Van" eisprong tot bevruchting>
    </div>
    <div class="col-md-6">
        <h5>Van eisprong tot bevruchting</h5>
        <p>Van eisprong (ovulatie) tot bevruchting tot implantatie, fascinerende microscoopbeelden bieden inzicht in de vruchtbaarheid van het vrouwelijk lichaam. Met verklarend lesmateriaal voor de seksuele voorlichtingslessen.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/van-eisprong-tot-bevruchting-4604.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/menstruatiecyclus-4605.png" class="img-fluid" alt="Menstruatiecyclus">
    </div>
    <div class="col-md-6">
        <h5>Menstruatiecyclus</h5>
        <p>Van ei rijping tot eisprong en de fase tot menstruatie. Lesmateriaal met afbeeldingen en verklarende teksten visualiseren het proces in een cyclus.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/menstruatiecyclus-4605.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/menstruatieverloop-4606.png" class="img-fluid" alt="Menstruatieverloop">
    </div>
    <div class="col-md-6">
        <h5>Menstruatieverloop</h5>
        <p>Typische verloop van de menstruatie en menstruatiefeiten.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/menstruatieverloop-4606.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/onregelmatige-menstruatiecycli-4607.png" class="img-fluid" alt="Onregelmatige" menstruatiecycli>
    </div>
    <div class="col-md-6">
        <h5>Onregelmatige menstruatiecycli</h5>
        <p>Regelmatige en onregelmatige cycli</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/onregelmatige-menstruatiecycli-4607.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/vrouwelijke-cyclusverloop-met-tekst-4608.png" class="img-fluid" alt="Vrouwelijke" cyclusverloop met tekst>
    </div>
    <div class="col-md-6">
        <h5>Vrouwelijke cyclusverloop met tekst</h5>
        <p>Begeleidend blad om de complexiteit van de vrouwelijke cyclus uit te leggen: wat gebeurt er in een vrouwelijk lichaam tijdens een cyclus en waarom treedt menstruatie op?</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/vrouwelijke-cyclusverloop-met-tekst-4608.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/1.-gemiddelde-cyclus-4609.png" class="img-fluid" alt="1." gemiddelde cyclus>
    </div>
    <div class="col-md-6">
        <h5>1. Gemiddelde cyclus</h5>
        <p>Horizontaal: Verklaring van de vrouwelijke cyclus 1/3: wat gebeurt er in het vrouwelijk lichaam in de 28 dagen en wanneer treedt menstruatie op? De eerste van de drie afbeeldingen verklaart het verloop van de cyclus.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/1.-gemiddelde-cyclus-4609.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/2.-de-mechanismen-van-de-cyclus-4610.png" class="img-fluid" alt="2." de mechanismen van cyclus>
    </div>
    <div class="col-md-6">
        <h5>2. De mechanismen van de cyclus</h5>
        <p>Horizontaal: verklaring van de vrouwelijke cyclus 2/3: hoe zijn de rijping en de oplossing van de eicel gerelateerd aan de opbouw en afbraak van de eicel en de menstruatie? De tweede van de drie afbeeldingen verklaart de verbanden.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/2.-de-mechanismen-van-de-cyclus-4610.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/3.-sturing-van-de-cyclus-4611.png" class="img-fluid" alt="3." sturing van de cyclus>
    </div>
    <div class="col-md-6">
        <h5>3. Sturing van de cyclus</h5>
        <p>Horizontaal: verklaring van de vrouwelijke cyclus 3/3: hoe sturen hormonen de cyclus. De derde van de drie grafieken verklaart de achtergronden.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/3.-sturing-van-de-cyclus-4611.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/lichamelijke-veranderingen-in-de-cyclus-4612.png" class="img-fluid" alt="Lichamelijke" veranderingen in de cyclus>
    </div>
    <div class="col-md-6">
        <h5>Lichamelijke veranderingen in de cyclus</h5>
        <p>Horizontaal: opmerkzame lichamelijke verandering tijdens de vrouwelijke cyclus (bijv. Lichaamstemperatuur, borstomvang of de hoeveelheid vaginale afscheiding. Afbeeldingen visualiseren verbanden.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/lichamelijke-veranderingen-in-de-cyclus-4612.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
</div>

<h3>Meer informatie</h3>
<p><a href="/multimedia/oefenbladen">Oefenbladen</a></p><p></p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-blue zij-menu p-3">
	<a href="/lesmaterialen" class="level-1 d-block ">Lesmaterialen voor seksuele voorlichtingslessen</a>
<a href="/lesmaterialen/themagebieden" class="level-2 d-block ">Themagebieden</a>
<a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="level-3 d-block ">Ontwikkeling en puberteit</a>
<a href="/lesmaterialen/themagebieden/geslachtsorganen" class="level-3 d-block ">Geslachtsorganen</a>
<a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="level-3 d-block text-rood">Cyclus en menstruatie</a>
<a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="level-3 d-block ">Menstruatie hygi&#xEB;ne</a>
<a href="/lesmaterialen/themagebieden/tampongebruik" class="level-3 d-block ">Tampongebruik</a>
<a href="/lesmaterialen/speciale-pakketten" class="level-2 d-block ">Speciale pakketten</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="level-3 d-block ">Lesmateriaal voor meisjes</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="level-3 d-block ">Lesmateriaal voor jongens</a>

<a href="/lesmaterialen/lespakket-gratis-bestellen" class="level-2 d-block ">Lespakket gratis bestellen</a>
<a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="level-3 d-block ">Docentenhandleiding</a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </main>


    
<div class="B00544">
    <div class="container-fluid pb-3">
        <div class="container">
            <div class="row mr-md-5 pr-md-5">
		<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="stretched-link"><img src="/uploads/callout1_jongen.png" class="img-fluid p-3" alt="Materiaal voor jongens"></a>
    <h3>Materiaal voor jongens</h3>
    <div class="pay-off">
        Lesmateriaal rondom het thema puberteit bij jongens
    </div>
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu downloaden</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten" class="stretched-link"><img src="/uploads/callout2_primair_onderwijs.png" class="img-fluid p-3" alt="Materiaal voor basisscholen"></a>
    <h3>Materiaal voor basisscholen</h3>
    <div class="pay-off">
        Lesmateriaal voor seksuele voorlichtingslessen
    </div>
    <a href="/lesmaterialen/speciale-pakketten" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu samenstellen</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lessen/lesvoorbereiding" class="stretched-link"><img src="/uploads/callout3_lesvoorbereiding.png" class="img-fluid p-3" alt="Hulp bij de lesvoorbereiding"></a>
    <h3>Hulp bij de lesvoorbereiding</h3>
    <div class="pay-off">
        Tips en checklists voor seksuele voorlichtingslessen
    </div>
    <a href="/lessen/lesvoorbereiding" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu informeren</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen" class="stretched-link"><img src="/uploads/callout5_lesmateriaal.png" class="img-fluid p-3" alt="Download lesmaterialen"></a>
    <h3>Download lesmaterialen</h3>
    <div class="pay-off">
        Uitgebreid aanbod van onderwerpen voor de seksuele voorlichting 
    </div>
    <a href="/lesmaterialen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Naar de materialen</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/multimedia" class="stretched-link"><img src="/uploads/callout6_multimedia.png" class="img-fluid p-3" alt="Multimedia voor seksuele voorlichting"></a>
    <h3>Multimedia voor seksuele voorlichting</h3>
    <div class="pay-off">
        Instructiefilm en interactieve kennistests voor seksuele voorlichting
    </div>
    <a href="/multimedia" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bekijken</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="stretched-link"><img src="/uploads/callout4_lespakket_bestellen.png" class="img-fluid p-3" alt="Gratis lespakket"></a>
    <h3>Gratis lespakket</h3>
    <div class="pay-off">
        Relevante lesmaterialen voor seksuele voorlichting
    </div>
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bestellen</a>
</div>
            </div>
        </div>
    </div>

</div>

        ` }} />
         <Footer></Footer>      
        </div>   


    );
  }
}

export default Page;

